import { useCallback, useState } from "react";
import s from "./DocumentPreview.module.scss";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers/rootReducers";
import {
  removeDocument,
  updateActiveDocumentId,
  updateAnnotationsToggle,
  updateDocument,
} from "../../../../reducers/modelReducer";
import IDocument from "../../../../models/Document";
import { useTranslation } from "react-i18next";
import { Spin, message } from "antd";
import { useDocumentService } from "../../../../providers/document.service.provider";
import { NoticeType } from "antd/es/message/interface";
import { ModelState } from "../../../../models/ModelState";
import { DocumentState } from "../../../../models/DocumentState";
import { DocumentStatus } from "../../../../models/DocumentStatus";
import { ModelMode } from "models/ModelMode";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

interface DocumentPreviewProps {
  document: IDocument;
  modelMode: ModelMode;
}

export default function DocumentPreview(props: DocumentPreviewProps) {
  const { t } = useTranslation();
  const { service } = useDocumentService();
  const dispatch = useDispatch();
  const { document } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const activeDocumentId = useSelector(
    (state: RootState) => state.model.activeDocumentId
  );

  const modelState = useSelector((state: RootState) => state.model.modelState);

  const [numPages, setNumPages] = useState(null);
  const [reloadEnable, setReloadEnable] = useState<boolean>(true);

  const onDocumentLoadSuccess = (numPages: any) => {
    setNumPages(numPages);
    if (document.status != DocumentStatus.PARSING) {
      document.status = DocumentStatus.LOADED;
      dispatch(updateDocument(document));
    }
  };

  const onDocumentLoadError = () => {
    document.status = DocumentStatus.LOAD_FAILED;
    dispatch(updateDocument(document));
  };

  const isLoading = useCallback(
    () =>
      document.status != DocumentStatus.LOADED &&
      document.status != DocumentStatus.LOAD_FAILED,
    [document]
  );

  const deleteDocument = async (idDocument: string) => {
    try {
      await service!.removeDocument(idDocument);
      dispatch(removeDocument(idDocument));
      showAlert(
        "success",
        t("ModelPage.alerts.successfullyRemovedDocumentAlert")
      );
    } catch (err) {
      showAlert("error", t("ModelPage.alerts.errorWhileRemovingDocument"));
      console.error(
        "An error occurred on the server while removing a document",
        err
      );
    }
  };

  const refreshDocument = async (idDocument: string) => {
    try {
      setReloadEnable(false);
      document.status = await DocumentStatus.RELOADING;
      await dispatch(updateDocument(document));
      const newdocument = await service!.getDocument(idDocument);
      (await newdocument).status = DocumentStatus.LOADED;
      await dispatch(updateAnnotationsToggle());
      await dispatch(updateDocument(newdocument, newdocument.idDocument));
      await showAlert(
        "success",
        t("ModelPage.alerts.successfullyReloadedDocumentAlert")
      );
    } catch (err) {
      showAlert("error", t("ModelPage.alerts.errorWhileReloadingDocument"));
      console.error(
        "An error occurred on the server while removing a document",
        err
      );
    }
  };

  const getStateClass = useCallback(
    () =>
      `${s["DocumentPreview-Status"]}${
        (document.state === DocumentState.Create &&
          ` ${s["DocumentPreview-Status__EnCourses"]}`) ||
        ""
      }${
        (document.state === DocumentState.Online &&
          ` ${s["DocumentPreview-Status__Does"]}`) ||
        ""
      }`,
    [document]
  );

  const getStateTitle = useCallback(() => {
    switch (document.state) {
      case DocumentState.Create:
        return t("shared.documentStates.enCourses");
      case DocumentState.Train:
        return t("shared.documentStates.does");
      case DocumentState.Online:
        return t("shared.documentStates.online");
      default:
        return "";
    }
  }, [document]);

  const showAlert = (type: NoticeType | undefined, message: string): void => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  return (
    <>
      <Spin
        tip={
          document.status == DocumentStatus.UPLOADING
            ? t("shared.uploadingText")
            : document.status == DocumentStatus.PARSING
            ? t("shared.parsingText")
            : document.status == DocumentStatus.RELOADING
            ? t("shared.reloadingText")
            : t("shared.spinText")
        }
        spinning={isLoading()}
      >
        <div
          className={
            activeDocumentId === document.idDocument
              ? s["DocumentPreview"] + " " + s["DocumentPreview__Active"]
              : s["DocumentPreview"]
          }
          onClick={() => {
            dispatch(updateActiveDocumentId(document.idDocument));
          }}
        >
          <div className={s["DocumentPreview-Document"]}>
            <Document
              file={document.url}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
              error={
                <div className={s["DocumentPreview-FailedToLoadPDFLabel"]}>
                  {t(
                    "ModelPage.documentViewerSection.errorWhileLoadingPreviewOfPDFFile"
                  )}
                </div>
              }
              onLoadError={() => onDocumentLoadError}
              loading=""
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  width={95}
                  pageNumber={index + 1}
                  className={s["DocumentPreview-Page"]}
                />
              ))}
            </Document>
          </div>

          <div className={s["DocumentPreview-FileName"]}>
            <span>
              {document.name ? document.name : "Document has not name"}
            </span>
          </div>

          <div className={getStateClass()}>{getStateTitle()}</div>

          {((modelState !== ModelState.Train &&
            modelState !== ModelState.Online) ||
            props.modelMode === ModelMode.Test) &&
            (props.modelMode !== ModelMode.Test ? (
              <svg
                className={s["DocumentPreview-DeleteIcon"]}
                fill="none"
                onClick={() => deleteDocument(document.idDocument)}
              >
                <path
                  d="M12.3333 5.00001V4.33334C12.3333 3.39992 12.3333 2.93321 12.1517 2.57669C11.9919 2.26308 11.7369 2.00812 11.4233 1.84833C11.0668 1.66667 10.6001 1.66667 9.66667 1.66667H8.33333C7.39991 1.66667 6.9332 1.66667 6.57668 1.84833C6.26308 2.00812 6.00811 2.26308 5.84832 2.57669C5.66667 2.93321 5.66667 3.39992 5.66667 4.33334V5.00001M7.33333 9.58334V13.75M10.6667 9.58334V13.75M1.5 5.00001H16.5M14.8333 5.00001V14.3333C14.8333 15.7335 14.8333 16.4335 14.5608 16.9683C14.3212 17.4387 13.9387 17.8212 13.4683 18.0609C12.9335 18.3333 12.2335 18.3333 10.8333 18.3333H7.16667C5.76654 18.3333 5.06647 18.3333 4.53169 18.0609C4.06129 17.8212 3.67883 17.4387 3.43915 16.9683C3.16667 16.4335 3.16667 15.7335 3.16667 14.3333V5.00001"
                  stroke="#475467"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <div>
                {reloadEnable && (
                  <svg
                    className={s["DocumentPreview-ReloadIcon"]}
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => {
                      refreshDocument(document.idDocument);
                      setReloadEnable(true);
                    }}
                  >
                    <path
                      d="M21.5 2v6h-6M21.34 15.57a10 10 0 1 1-.57-8.38"
                      stroke="#475467"
                      strokeWidth="2.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                <svg
                  className={s["DocumentPreview-DeleteIcon"]}
                  fill="none"
                  onClick={() => deleteDocument(document.idDocument)}
                >
                  <path
                    d="M12.3333 5.00001V4.33334C12.3333 3.39992 12.3333 2.93321 12.1517 2.57669C11.9919 2.26308 11.7369 2.00812 11.4233 1.84833C11.0668 1.66667 10.6001 1.66667 9.66667 1.66667H8.33333C7.39991 1.66667 6.9332 1.66667 6.57668 1.84833C6.26308 2.00812 6.00811 2.26308 5.84832 2.57669C5.66667 2.93321 5.66667 3.39992 5.66667 4.33334V5.00001M7.33333 9.58334V13.75M10.6667 9.58334V13.75M1.5 5.00001H16.5M14.8333 5.00001V14.3333C14.8333 15.7335 14.8333 16.4335 14.5608 16.9683C14.3212 17.4387 13.9387 17.8212 13.4683 18.0609C12.9335 18.3333 12.2335 18.3333 10.8333 18.3333H7.16667C5.76654 18.3333 5.06647 18.3333 4.53169 18.0609C4.06129 17.8212 3.67883 17.4387 3.43915 16.9683C3.16667 16.4335 3.16667 15.7335 3.16667 14.3333V5.00001"
                    stroke="#475467"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            ))}
        </div>

        {contextHolder}
      </Spin>
    </>
  );
}
