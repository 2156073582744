import { IAnnotationLabel } from "../pages/Model/DocumentViewer/AddAnnotationWindow/SetAnnotationLabel/SetAnnotationLabel";
import { IAnnotation } from "../reducers/modelReducer";
import IAnntotationPostData from "../models/AnntotionPostData";
import IDocument from "../models/Document";
import React, { ReactNode, useContext, useMemo } from "react";
import { useAthenticatedAxios } from "hooks/axios.hooks";
import { createAxiosInstance } from "utils/axios.helper";
import { useAuth } from "./auth.service.provider";
import DocumentService from "services/document.service";

interface DocumentServiceContextValues {
  service?: DocumentService;
}

const DocumentServiceContext: React.Context<DocumentServiceContextValues> =
  React.createContext<DocumentServiceContextValues>({});

interface DocumentServiceProviderProps {
  children?: ReactNode;
}

export const DocumentServiceProvider = ({
  children,
}: DocumentServiceProviderProps) => {
  const { token } = useAuth();
  const axiosInstance = useMemo(() => createAxiosInstance(token), [token]);

  const service = useMemo(
    () => new DocumentService(axiosInstance),
    [axiosInstance]
  );
  return (
    <DocumentServiceContext.Provider value={{ service }}>
      {children}
    </DocumentServiceContext.Provider>
  );
};

export const useDocumentService = (): DocumentServiceContextValues => {
  const context = useContext<DocumentServiceContextValues>(
    DocumentServiceContext
  );
  return context;
};
