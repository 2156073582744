import { useDispatch, useSelector } from "react-redux";
import {
  IAnnotation,
  ModelPageMode,
  setAnountOfAnnotationsForDocument,
} from "../../../reducers/modelReducer";
import { RootState } from "../../../reducers/rootReducers";
import Annotation from "./Annotation/Annotation";
import s from "./AnnotationsList.module.scss";
import NoAnnotations from "./NoAnnotations/NoAnnotations";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAnnotationService } from "../../../providers/annotation.service.provider";
import { message } from "antd";
import { NoticeType } from "antd/es/message/interface";
import { ModelMode } from "models/ModelMode";

interface IProps {
  modelMode: ModelMode;
}

export default function AnnotationsList({ modelMode }: IProps) {
  const { t } = useTranslation();
  const { service } = useAnnotationService();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  const activeFileIndex = useSelector((state: RootState) =>
    state.model.documents.findIndex(
      (document) => document.idDocument === state.model.activeDocumentId
    )
  );
  const activeDocumentId = useSelector(
    (state: RootState) => state.model.activeDocumentId
  );

  const documents = useSelector((state: RootState) => state.model.documents);
  const mode = useSelector((state: RootState) => state.model.mode);
  const updateAnnotationsToggle = useSelector(
    (state: RootState) => state.model.updateAnnotationsToggle
  );

  const [annotationsForDocument, setAnnotationsForDocument] = useState<
    IAnnotation[]
  >([]);

  const getAnnotationForDocument = (idDocument: string) => {
    service!
      .getAnnotationForDocument(idDocument)
      .then(async (annotations: IAnnotation[]) => {
        setAnnotationsForDocument(annotations);
        dispatch(
          setAnountOfAnnotationsForDocument(activeFileIndex, annotations.length)
        );
      })
      .catch((err: any) => {
        showAlert(
          "error",
          t("ModelPage.alerts.errorWhileGetingAnnotationForDocument")
        );
        console.error(
          "An error occurred on the server while geting annotations for document",
          err
        );
      });
  };

  const showAlert = (type: NoticeType | undefined, message: string): void => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  useEffect(() => {
    documents[activeFileIndex] &&
      getAnnotationForDocument(documents[activeFileIndex].idDocument);
  }, [activeFileIndex, updateAnnotationsToggle, activeDocumentId]);

  return (
    <div className={s["AnnotationsList"]}>
      <h2 className={s["AnnotationsList-Title"]}>
        {t("ModelPage.annotationsListSection.title")}
      </h2>

      {mode !== ModelPageMode.EMPTY &&
        annotationsForDocument.map((annotation: IAnnotation) => {
          return (
            <Annotation
              key={annotation.idAnnotation}
              type={annotation.type}
              iconColor={annotation.colorHexa}
              name={annotation.name}
              persent={annotation?.confidence}
              accuracy={annotation?.accuracy}
              precision={annotation?.precision}
              id={annotation.idAnnotation}
              wordContent={annotation.wordContent}
            />
          );
        })}

      {mode === ModelPageMode.EMPTY && <NoAnnotations />}

      {contextHolder}
    </div>
  );
}
