import { AxiosInstance } from "axios";
import { IAnnotationLabel } from "../pages/Model/DocumentViewer/AddAnnotationWindow/SetAnnotationLabel/SetAnnotationLabel";
import { IAnnotation } from "../reducers/modelReducer";
import IAnntotationPostData from "models/AnntotionPostData";

export default class AnnotationService {
  axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  async fetchAnnotationsList(modelId: string): Promise<IAnnotation[]> {
    return this.axiosInstance
      .get(`/ModelService/Annotation/GetModel/${modelId}`)
      .then((response) => response.data);
  }

  getAnnotationLabels(annotationList: IAnnotation[]): IAnnotationLabel[] {
    const annotationLabelsArr: IAnnotationLabel[] = annotationList.map(
      (annotation: IAnnotation) => ({
        id: annotation.idAnnotation,
        name: annotation.name,
        iconColor: annotation.colorHexa,
        annotationLabelType: annotation.type,
      })
    );

    function removeEqualAnnotationLabels(
      arr: IAnnotationLabel[]
    ): IAnnotationLabel[] {
      let resultArr: IAnnotationLabel[] = [];

      for (let annotationLabel of arr) {
        const itemExistInArr: IAnnotationLabel | undefined = resultArr.find(
          (item) => item.name === annotationLabel.name
        );

        if (!itemExistInArr) {
          resultArr.push(annotationLabel);
        }
      }

      return resultArr;
    }

    return removeEqualAnnotationLabels(annotationLabelsArr);
  }

  async createNewAnnotation(
    newAnnotationData: IAnntotationPostData,
    wordsIdArr: string[]
  ): Promise<IAnnotation> {
    const response = await this.axiosInstance.post(
      `/ModelService/Annotation/CreateAnnotation`,
      newAnnotationData
    );
    for (let i = 0; i < wordsIdArr.length; i++) {
      await this.axiosInstance.put(`/ModelService/Word/UpdateAnnotation`, {
        idWord: wordsIdArr[i],
        idAnnotation: response.data.idAnnotation,
      });
    }
    return response.data;
  }

  async connectExistingAnnotationWithWords(
    annotationId: string,
    wordsIdArr: string[]
  ): Promise<void> {
    for (let i = 0; i < wordsIdArr.length; i++) {
      this.axiosInstance.put(`/ModelService/Word/UpdateAnnotation`, {
        idWord: wordsIdArr[i],
        idAnnotation: annotationId,
      });
    }
  }

  async removeAnnotation(idAnnotation: string): Promise<boolean> {
    return this.axiosInstance
      .delete(`/ModelService/Annotation/DeleteAnnotation/${idAnnotation}`)
      .then((response) => (response.data ? true : false));
  }

  async getAnnotationForDocument(idDocument: string): Promise<IAnnotation[]> {
    return this.axiosInstance
      .get(`/ModelService/Annotation/GetModelByDocument/${idDocument}`)
      .then((response) => response.data);
  }
}
