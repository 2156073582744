import IDocument from "../models/Document";
import { ModelState } from "../models/ModelState";
import { IAnnotationLabel } from "../pages/Model/DocumentViewer/AddAnnotationWindow/SetAnnotationLabel/SetAnnotationLabel";

export interface IAnnotation {
  idAnnotation: string;
  idWord: string;
  wordContent: any | null;
  idModel: string;
  name: string;
  type: number;
  colorHexa: string;
  confidence?: number;
  accuracy?: number;
  precision?: number;
}

export interface IQuality {
  type: string;
  accuracy: number;
  precision: number;
  recall: number;
  selected: boolean;
}

export enum ModelPageMode {
  "EMPTY" = "EMPTY",
  "ANNOTATION" = "ANNOTATION",
  "ONLINE" = "ONLINE",
}

export interface IModelReducerState {
  documents: IDocument[];
  activeDocumentId: string;
  amountOfFiles: number;
  annotationList: IAnnotation[];
  mode: ModelPageMode;
  annotationLabels: IAnnotationLabel[];
  annotationTypes: number[];
  updateAnnotationsToggle: boolean;
  amountOfAnnotationPerDocument: number[];
  modelState: ModelState;
  modelQuality: IQuality[];
}

const initialState: IModelReducerState = {
  documents: [],
  activeDocumentId: "",
  amountOfFiles: 0,
  annotationList: [],
  mode: ModelPageMode.EMPTY,
  annotationLabels: [],
  annotationTypes: [],
  updateAnnotationsToggle: true,
  amountOfAnnotationPerDocument: [],
  modelState: ModelState.Create,
  modelQuality: [],
};

export const ModelReducer = (
  state = initialState,
  action: ActionsTypes
): IModelReducerState => {
  const documentIndex: number = state.documents.findIndex(
    (document) => document.idDocument === state.activeDocumentId
  );

  switch (action.type) {
    case "[Model page] update mode":
      return { ...state, mode: action.payload };

    case "[Model page] update quality":
      return { ...state, modelQuality: action.payload };

    case "[Model page] add empty file":
      return {
        ...state,
        documents: [action.payload, ...state.documents],
        amountOfAnnotationPerDocument: [
          0,
          ...state.amountOfAnnotationPerDocument,
        ],
      };

    case "[Model page] update document":
      const newDocument: IDocument = action.payload.document;
      const updatingDocumentId: string =
        action.payload.documentId || action.payload.document.idDocument;

      return {
        ...state,
        documents: state.documents.map((document) =>
          document.idDocument === updatingDocumentId ? newDocument : document
        ),
      };

    case "[Model page] remove file":
      const documentId: string = action.payload;

      const documents = state.documents.filter(
        (document) => document.idDocument !== documentId
      );
      const amountOfAnnotationPerDocument =
        state.amountOfAnnotationPerDocument.filter(
          (_, index) => index !== documentIndex
        );

      let activeDocumentId: string = state.activeDocumentId;
      if (documentId === state.activeDocumentId) {
        const count = documents.length;
        if (!count) activeDocumentId = "";
        else {
          const lastIndex = count - 1;
          activeDocumentId =
            documents[documentIndex >= lastIndex ? lastIndex : documentIndex]
              .idDocument;
        }
      }

      return {
        ...state,
        documents,
        amountOfAnnotationPerDocument,
        activeDocumentId,
        amountOfFiles: state.amountOfFiles - 1,
      };

    case "[Model page] update active document id":
      return { ...state, activeDocumentId: action.payload };

    case "[Model page] set annotation labels":
      return { ...state, annotationLabels: action.payload };

    case "[Model page] set annotation types":
      return { ...state, annotationTypes: action.payload };

    case "[Model page] add new annotation":
      return {
        ...state,
        annotationList: [...state.annotationList, action.payload],
        amountOfAnnotationPerDocument: state.amountOfAnnotationPerDocument.map(
          (amount, index) => (index === documentIndex ? amount + 1 : amount)
        ),
      };

    case "[Model page] delete annotation":
      return {
        ...state,
        annotationList: state.annotationList.filter(
          (annotation) => annotation.idAnnotation !== action.payload
        ),
        amountOfAnnotationPerDocument: state.amountOfAnnotationPerDocument.map(
          (amount, index) => (index === documentIndex ? amount - 1 : amount)
        ),
      };

    case "[Model page] update annotations toggle":
      return {
        ...state,
        updateAnnotationsToggle: !state.updateAnnotationsToggle,
      };

    case "[Model page] set documents":
      const documentsArr: IDocument[] = action.payload;
      let updatedAmountOfAnnotationPerDocument: number[] = [
        ...state.amountOfAnnotationPerDocument,
      ];

      for (let i = 0; i < documentsArr.length; i++) {
        updatedAmountOfAnnotationPerDocument.push(0);
      }

      return {
        ...state,
        documents: [...documentsArr],
        amountOfAnnotationPerDocument: updatedAmountOfAnnotationPerDocument,
      };

    case "[Model page] set annotations":
      return { ...state, annotationList: [...action.payload] };

    case "[Model page] set model state":
      return { ...state, modelState: action.payload };

    case "[Model page] set amount of annotation for document":
      var newAmountOfAnnotationPerDocumentArr =
        state.amountOfAnnotationPerDocument;
      newAmountOfAnnotationPerDocumentArr[action.payload.documentIndex] =
        action.payload.amountOfAnnotations;

      return {
        ...state,
        amountOfAnnotationPerDocument: [...newAmountOfAnnotationPerDocumentArr],
      };

    default:
      return state;
  }
};
interface IUpdateQualityAction {
  type: "[Model page] update quality";
  payload: IQuality[];
}

export const updateQuality = (quality: IQuality[]): IUpdateQualityAction => ({
  type: "[Model page] update quality",
  payload: quality,
});

interface IUpdateModeAction {
  type: "[Model page] update mode";
  payload: ModelPageMode;
}

export const updateMode = (mode: ModelPageMode): IUpdateModeAction => ({
  type: "[Model page] update mode",
  payload: mode,
});

interface IAddEmptyDocumentAction {
  type: "[Model page] add empty file";
  payload: IDocument;
}

export const addEmptyDocument = (
  document: IDocument
): IAddEmptyDocumentAction => ({
  type: "[Model page] add empty file",
  payload: document,
});

interface IUpdateDocumentAction {
  type: "[Model page] update document";
  payload: IUpdateDocumentActionPayload;
}

interface IUpdateDocumentActionPayload {
  document: IDocument;
  documentId?: string;
}

export const updateDocument = (
  document: IDocument,
  documentId?: string
): IUpdateDocumentAction => ({
  type: "[Model page] update document",
  payload: {
    document,
    documentId,
  },
});

interface IUpdateActiveDocumentIdAction {
  type: "[Model page] update active document id";
  payload: string;
}

export const updateActiveDocumentId = (
  documentId: string
): IUpdateActiveDocumentIdAction => ({
  type: "[Model page] update active document id",
  payload: documentId,
});

interface IIncrementAmountOfFilesAction {
  type: "[Model page] increment amount of files";
}

export const incrementAmountOfFiles = (): IIncrementAmountOfFilesAction => ({
  type: "[Model page] increment amount of files",
});

interface IDecrementAmountOfFilesAction {
  type: "[Model page] decrement amount of files";
}

export const decrementAmountOfFiles = (): IDecrementAmountOfFilesAction => ({
  type: "[Model page] decrement amount of files",
});

interface ISetAnnotationLabelsAction {
  type: "[Model page] set annotation labels";
  payload: IAnnotationLabel[];
}

export const setAnnotationLabels = (
  annotationLabels: IAnnotationLabel[]
): ISetAnnotationLabelsAction => ({
  type: "[Model page] set annotation labels",
  payload: annotationLabels,
});

interface ISetAnnotationTypesAction {
  type: "[Model page] set annotation types";
  payload: number[];
}

export const setAnnotationTypes = (
  annotationLabels: number[]
): ISetAnnotationTypesAction => ({
  type: "[Model page] set annotation types",
  payload: annotationLabels,
});

interface IAddNewAnnotationAction {
  type: "[Model page] add new annotation";
  payload: IAnnotation;
}

export const addNewAnnotation = (
  newAnnotation: IAnnotation
): IAddNewAnnotationAction => ({
  type: "[Model page] add new annotation",
  payload: newAnnotation,
});

interface IDeleteAnnotationAction {
  type: "[Model page] delete annotation";
  payload: string;
}

export const deleteAnnotation = (
  annotationId: string
): IDeleteAnnotationAction => ({
  type: "[Model page] delete annotation",
  payload: annotationId,
});

interface IUpdateAnnotationsToggleAction {
  type: "[Model page] update annotations toggle";
}

export const updateAnnotationsToggle = (): IUpdateAnnotationsToggleAction => ({
  type: "[Model page] update annotations toggle",
});

interface ISetDocumentsAction {
  type: "[Model page] set documents";
  payload: IDocument[];
}

export const setDocuments = (
  documentsArr: IDocument[]
): ISetDocumentsAction => ({
  type: "[Model page] set documents",
  payload: documentsArr,
});

interface ISetAnnotationsAction {
  type: "[Model page] set annotations";
  payload: IAnnotation[];
}

export const setAnnotations = (
  annotationsArr: IAnnotation[]
): ISetAnnotationsAction => ({
  type: "[Model page] set annotations",
  payload: annotationsArr,
});

interface IRemoveDocumentAction {
  type: "[Model page] remove file";
  payload: string;
}

export const removeDocument = (documentId: string): IRemoveDocumentAction => ({
  type: "[Model page] remove file",
  payload: documentId,
});

interface ISetModelStateAction {
  type: "[Model page] set model state";
  payload: ModelState;
}

export const setModelState = (
  modelState: ModelState
): ISetModelStateAction => ({
  type: "[Model page] set model state",
  payload: modelState,
});

interface ISetAnountOfAnnotationsForDocumentAction {
  type: "[Model page] set amount of annotation for document";
  payload: {
    documentIndex: number;
    amountOfAnnotations: number;
  };
}

export const setAnountOfAnnotationsForDocument = (
  documentIndex: number,
  amountOfAnnotations: number
): ISetAnountOfAnnotationsForDocumentAction => ({
  type: "[Model page] set amount of annotation for document",
  payload: {
    documentIndex,
    amountOfAnnotations,
  },
});

type ActionsTypes =
  | ReturnType<typeof updateQuality>
  | ReturnType<typeof updateMode>
  | ReturnType<typeof addEmptyDocument>
  | ReturnType<typeof updateActiveDocumentId>
  | ReturnType<typeof incrementAmountOfFiles>
  | ReturnType<typeof decrementAmountOfFiles>
  | ReturnType<typeof setAnnotationLabels>
  | ReturnType<typeof setAnnotationTypes>
  | ReturnType<typeof addNewAnnotation>
  | ReturnType<typeof deleteAnnotation>
  | ReturnType<typeof updateAnnotationsToggle>
  | ReturnType<typeof setDocuments>
  | ReturnType<typeof setAnnotations>
  | ReturnType<typeof removeDocument>
  | ReturnType<typeof updateDocument>
  | ReturnType<typeof setModelState>
  | ReturnType<typeof setAnountOfAnnotationsForDocument>;
