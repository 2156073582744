import TestModel from "pages/Model/TestModel";
import { createBrowserRouter } from "react-router-dom";
import MainLayout from "./components/Layout/MainLayout";
import Home from "./pages/Home/Home";
import Model from "./pages/Model/Model";

export default createBrowserRouter([
  {
    path: "",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/model/:modelId",
        element: <Model />,
      },
      {
        path: "/model/:modelId/Test",
        element: <TestModel />,
      },
    ],
  },
]);
