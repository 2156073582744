import axios from "axios";
import Config from "config.json";

export function createAxiosInstance(accessToken?: string | null) {
    const instance = axios.create({ baseURL: Config.WebApps.ApiGateway.URL });
  if (accessToken) {
    instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  }
  return instance;
}

const defaultInstance = createAxiosInstance();
export default defaultInstance;
