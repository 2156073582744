import React, { Suspense } from "react"
import ReactDOM from 'react-dom/client'
import { Provider } from "react-redux"
import { RouterProvider } from "react-router-dom"
import AppRouter from "./router"
import { store } from "./store"
import './index.css'
import './i18n'
import LoaderForSuspenseComponent from "./components/LoaderForSuspenseComponent/LoaderForSuspenseComponent"
import { Amplify } from 'aws-amplify'
import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <Suspense fallback={<LoaderForSuspenseComponent />}>
      <Provider store={store}>
        <RouterProvider router={AppRouter} />
      </Provider>
    </Suspense>
  </React.StrictMode>
)

reportWebVitals()