import React, { ReactNode, useContext, useMemo } from "react";
import { createAxiosInstance } from "utils/axios.helper";
import { useAuth } from "./auth.service.provider";
import ModelService from "services/model.service";

interface ModelServiceContextValues {
  service?: ModelService;
}

const ModelServiceContext: React.Context<ModelServiceContextValues> =
  React.createContext<ModelServiceContextValues>({});

interface ModelServiceProviderProps {
  children?: ReactNode;
}

export const ModelServiceProvider = ({
  children,
}: ModelServiceProviderProps) => {
  const { token } = useAuth();
  const axiosInstance = useMemo(() => createAxiosInstance(token), [token]);

  const service = useMemo(
    () => new ModelService(axiosInstance),
    [axiosInstance]
  );

  return (
    <ModelServiceContext.Provider value={{ service }}>
      {children}
    </ModelServiceContext.Provider>
  );
};

export const useModelService = (): ModelServiceContextValues => {
  const context = useContext<ModelServiceContextValues>(ModelServiceContext);
  return context;
};
