import React, { useContext, useEffect } from "react";
import { ReactNode } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import Config from "../config.json";
interface AuthContextValues {
  token: string;
}
const AuthContext: React.Context<AuthContextValues> =
  React.createContext<AuthContextValues>({
    token: "",
  });
interface AuthProviderProps {
  children: ReactNode;
}
export function AuthProvider({ children }: AuthProviderProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [storedToken, saveToken] = useLocalStorage("token", "");

  const navigate = useNavigate();

  useEffect(() => {
    async function loadToken() {
      const token = searchParams.get("token");
      if (token) {
        await saveToken(token);
        searchParams.delete("token");
        setSearchParams(searchParams, { replace: true });
      } else if (!storedToken) {
          navigate(Config.WebApps.ApiGateway.URL);
      }
    }
    loadToken();
  }, [navigate, searchParams, storedToken, saveToken, setSearchParams]);

  return (
    <AuthContext.Provider
      value={{
        token: storedToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
export const useAuth = (): AuthContextValues => {
  const context = useContext<AuthContextValues>(AuthContext);
  return context;
};
