import React, { ReactNode, useContext, useMemo } from "react";
import { createAxiosInstance } from "utils/axios.helper";
import { useAuth } from "./auth.service.provider";
import AnnotationService from "services/annotation.service";

interface AnnotationServiceContextValues {
  service?: AnnotationService;
}

const AnnotationServiceContext: React.Context<AnnotationServiceContextValues> =
  React.createContext<AnnotationServiceContextValues>({});

interface AnnotationServiceProviderProps {
  children?: ReactNode;
}

export const AnnotationServiceProvider = ({
  children,
}: AnnotationServiceProviderProps) => {
  const { token } = useAuth();
  const axiosInstance = useMemo(() => createAxiosInstance(token), [token]);

  const service = useMemo(
    () => new AnnotationService(axiosInstance),
    [axiosInstance]
  );
  return (
    <AnnotationServiceContext.Provider value={{ service }}>
      {children}
    </AnnotationServiceContext.Provider>
  );
};

export const useAnnotationService = (): AnnotationServiceContextValues => {
  const context = useContext<AnnotationServiceContextValues>(
    AnnotationServiceContext
  );
  return context;
};
