import { useEffect, useState } from "react";
import s from "./DocumentViewer.module.scss";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import ViewerControls from "./ViewerControls/ViewerControls";
import WordsContainers from "./WordsContainers/WordsContainers";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/rootReducers";
import { useAnnotationService } from "../../../providers/annotation.service.provider";
import { message } from "antd";
import { NoticeType } from "antd/es/message/interface";
import { IAnnotationLabel } from "./AddAnnotationWindow/SetAnnotationLabel/SetAnnotationLabel";
import {
  IAnnotation,
  ModelPageMode,
  setAnnotationLabels,
  setAnnotations,
  setAnnotationTypes,
} from "../../../reducers/modelReducer";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Config from "../../../config.json";
import { ModelState } from "../../../models/ModelState";
import IDocument from "../../../models/Document";
import { ModelMode } from "models/ModelMode";
import { DocumentState } from "models/DocumentState";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

interface IProps {
  modelMode: ModelMode;
}

export default function DocumentViewer({ modelMode }: IProps) {
  const { t } = useTranslation();
  const { service } = useAnnotationService();
  const dispatch = useDispatch();
  const { modelId } = useParams();

  const [messageApi, contextHolder] = message.useMessage();

  const document: IDocument | undefined = useSelector((state: RootState) =>
    state.model.documents.find(
      (document) => document.idDocument === state.model.activeDocumentId
    )
  );
  const updateAnnotationsToggle = useSelector(
    (state: RootState) => state.model.updateAnnotationsToggle
  );
  const mode = useSelector((state: RootState) => state.model.mode);
  const modelState = useSelector((state: RootState) => state.model.modelState);

  const [numPages, setNumPages] = useState<number>(0);
  const [currentPageNum, setCurrentPageNum] = useState<number>(1);

  const onDocumentLoadSuccess = (PDFData: any): void => {
    setNumPages(PDFData?._pdfInfo?.numPages);
  };

  const onPageChange = (action: "increase" | "decrease"): void => {
    const nextPageNum =
      action === "increase" ? currentPageNum + 1 : currentPageNum - 1;

    if (nextPageNum > numPages || nextPageNum === 0) return;

    setCurrentPageNum(nextPageNum);
  };

  const fetchAnnotationsList = (modelId: string) => {
    service!
      .fetchAnnotationsList(modelId)
      .then((annotationsList: IAnnotation[]) => {
        getAnnotationLabels(annotationsList);
        dispatch(setAnnotationTypes(Config.WebApps.Model.AnnotationType));
        dispatch(setAnnotations(annotationsList));
      })
      .catch((err: any) => {
        showAlert(
          "error",
          t("ModelPage.alerts.errorWhileGetingAnnotationsList")
        );
        console.error(
          "An error occurred on the server while geting annotations list",
          err
        );
      });
  };

  const getAnnotationLabels = (annotationsList: IAnnotation[]) => {
    const annotationLabels: IAnnotationLabel[] =
      service!.getAnnotationLabels(annotationsList);
    dispatch(setAnnotationLabels(annotationLabels));
  };

  const showAlert = (type: NoticeType | undefined, message: string): void => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  const calcPageWidth = (): number => {
    if (!document) return 0;

    const currentPageWidth =
      document.pages && document.pages[currentPageNum - 1]?.dimension
        ? document.pages[currentPageNum - 1]?.dimension.x
        : 1000;
    return currentPageWidth - (currentPageWidth * 33.11) / 100;
  };

  useEffect(() => {
    modelId && fetchAnnotationsList(modelId);
  }, [modelId, updateAnnotationsToggle]);

  if (!document) return <>{contextHolder}</>;

  return (
    <>
      <div className={s["DocumentViewer"]}>
        <Document
          file={document.url}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          error={
            t("ModelPage.documentViewerSection.errorWhileLoadingPDFFile") + ""
          }
          className={s["DocumentViewer-Document"]}
        >
          <Page
            pageNumber={currentPageNum}
            width={calcPageWidth()}
            className={s["DocumentViewer-Page"]}
          />

          {(mode === ModelPageMode.ANNOTATION ||
            modelMode === ModelMode.Test) &&
            document.pages &&
            document.state === DocumentState.Train &&
            document.pages[currentPageNum - 1]?.words.length > 0 &&
            ((modelState !== ModelState.Train &&
              modelState !== ModelState.Online) ||
              modelMode === ModelMode.Test) && (
              <WordsContainers
                words={document.pages[currentPageNum - 1]?.words}
                modelMode={modelMode}
              />
            )}
        </Document>
      </div>

      {numPages && (
        <div className={s["DocumentViewer-Controls"]}>
          <ViewerControls
            onPageChange={onPageChange}
            currentPageNum={currentPageNum}
            setCurrentPageNum={setCurrentPageNum}
            numPages={numPages}
          />
        </div>
      )}

      {contextHolder}
    </>
  );
}
