import { AxiosInstance } from "axios";
import IDocument from "../models/Document";
import { DocumentStatus } from "../models/DocumentStatus";

export default class DocumentService {
  axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  async uploadPDFFile(idModel: string, file: File, type: string): Promise<any> {
    const formData = new FormData();
    formData.append("idModel", idModel);
    formData.append("file", file);
    formData.append("type", type);
    return this.axiosInstance
      .post(`/ModelService/Document/CreateDocument`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => data);
  }

  async removeDocument(idDocument: string): Promise<void> {
    return this.axiosInstance.delete(
      `/ModelService/Document/DeleteDocument/${idDocument}`
    );
  }

  async getDocument(idDocument: string): Promise<IDocument> {
    return this.axiosInstance
      .get(`/ModelService/Document/GetDocument/${idDocument}`)
      .then((response) => ({
        ...response.data,
        status: DocumentStatus.LOADING,
      }));
  }
}
