import { Row, Col, message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ModelPageHeader from "./ModelPageHeader/ModelPageHeader";
import {
  ModelPageMode,
  setDocuments,
  setModelState,
  updateActiveDocumentId,
  updateMode,
  updateQuality,
} from "../../reducers/modelReducer";
import { RootState } from "../../reducers/rootReducers";
import AnnotationsList from "./AnnotationsList/AnnotationsList";
import DocumentViewer from "./DocumentViewer/DocumentViewer";
import s from "./Model.module.scss";
import DocumentsList from "./DocumentsList/DocumentsList";
import Layout from "../../components/Layout/Layout";
import NoDocuments from "./NoDocuments/NoDocuments";
import IModel from "../../models/Model";
import { useModelService } from "providers/model.service.provider";
import { useEffect, useState } from "react";
import { NoticeType } from "antd/es/message/interface";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ModelMode } from "models/ModelMode";

export default function Model() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mode = useSelector((state: RootState) => state.model.mode);
  const [messageApi, contextHolder] = message.useMessage();
  let { modelId } = useParams();

  const [modelName, setModelName] = useState<string>("");
  const [loaderIsActive, setLoaderIsActive] = useState<boolean>(false);
  let modelDataIsReceived: boolean = false;

  const { service } = useModelService();

  const getModelData = (modelId: string) => {
    setLoaderIsActive(true);

    service!
      .getTrainModelData(modelId)
      .then((modelData: IModel) => {
        setModelName(modelData?.name || "");
        dispatch(setDocuments(modelData.documents));

        if (modelData.documents.length > 0) {
          dispatch(updateActiveDocumentId(modelData.documents[0].idDocument));
          dispatch(updateMode(ModelPageMode.ANNOTATION));
        } else {
          dispatch(updateActiveDocumentId(""));
          dispatch(updateMode(ModelPageMode.EMPTY));
        }
        if (modelData.quality.length > 0) {
          dispatch(updateQuality(modelData.quality));
        }

        dispatch(setModelState(modelData.state));
      })
      .catch((err: any) => {
        showAlert(
          "error",
          t("ModelPage.alerts.errorWhileGetingInfoAboutModel")
        );
        console.error(
          "An error occurred on the server while geting info about model",
          err
        );
      })
      .finally(() => {
        setLoaderIsActive(false);
      });
  };

  const showAlert = (type: NoticeType | undefined, message: string): void => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  useEffect(() => {
    if (modelId && !modelDataIsReceived) {
      modelDataIsReceived = true;
      getModelData(modelId);
    }
  }, [modelId]);

  return (
    <Spin tip={t("shared.spinText")} spinning={loaderIsActive}>
      <Layout>
        <ModelPageHeader
          modelName={modelName}
          loading={setLoaderIsActive}
          modelMode={ModelMode.Train}
        />

        <div className={s["ModelPage"]}>
          <Row className={s["ModelPage-Row"]}>
            <Col className={s["ModelPage-Col"]} flex="290px">
              <DocumentsList modelMode={ModelMode.Train} />
            </Col>

            <Col className={s["ModelPage-Col"]} flex="auto">
              {mode !== ModelPageMode.EMPTY && (
                <DocumentViewer modelMode={ModelMode.Train} />
              )}
              {mode === ModelPageMode.EMPTY && <NoDocuments />}
            </Col>

            <Col className={s["ModelPage-Col"]} flex="340px">
              {mode !== ModelPageMode.EMPTY && (
                <AnnotationsList modelMode={ModelMode.Train} />
              )}
            </Col>
          </Row>
        </div>

        {contextHolder}
      </Layout>
    </Spin>
  );
}
