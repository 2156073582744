import { AnnotationServiceProvider } from "providers/annotation.service.provider";
import { DocumentServiceProvider } from "providers/document.service.provider";
import { ModelServiceProvider } from "providers/model.service.provider";
import { Outlet } from "react-router-dom";
import { AuthProvider } from "../../providers/auth.service.provider";

export default function MainLayout() {
  return (
    <AuthProvider>
      <ModelServiceProvider>
        <DocumentServiceProvider>
          <AnnotationServiceProvider>
            <Outlet />
          </AnnotationServiceProvider>
        </DocumentServiceProvider>
      </ModelServiceProvider>
    </AuthProvider>
  );
}
