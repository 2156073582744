import { CloseOutlined } from "@ant-design/icons";
import { Row, Col, Button, message, Modal, Table, Spin } from "antd";
import { NoticeType } from "antd/es/message/interface";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Config from "../../../config.json";
import {
  ModelPageMode,
  setModelState,
  updateMode,
} from "../../../reducers/modelReducer";
import { RootState } from "../../../reducers/rootReducers";
import { showWarningModal } from "../../../reducers/warningModalReducer";
import s from "./ModelPageHeader.module.scss";
import { useTranslation } from "react-i18next";
import EditModelName from "./EditModelName/EditModelName";
import { useEffect, useState } from "react";
import { ModelState } from "../../../models/ModelState";
import { useModelService } from "providers/model.service.provider";
import { ModelMode } from "models/ModelMode";

interface IProps {
  modelName: string;
  loading: Function;
  modelMode: ModelMode;
}

export default function ModelPageHeader({
  modelName,
  loading,
  modelMode,
}: IProps) {
  const { t } = useTranslation();
  const { service } = useModelService();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modelId } = useParams();
  const [messageApi, contextHolder] = message.useMessage();

  const mode = useSelector((state: RootState) => state.model.mode);
  const amountOfDocuments = useSelector(
    (state: RootState) => state.model.amountOfFiles
  );
  const amountOfAnnotationPerDocument = useSelector(
    (state: RootState) => state.model.amountOfAnnotationPerDocument
  );
  const modelState = useSelector((state: RootState) => state.model.modelState);
  const quality = useSelector((state: RootState) => state.model.modelQuality);
  const [trainBtnIsClickable, setTrainBtnIsClickable] =
    useState<boolean>(false);
  const [qualityModalVisible, setQualityModalVisible] =
    useState<boolean>(false);

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Accuracy",
      dataIndex: "accuracy",
      key: "accuracy",
      render: (row: number) => {
        return (row * 100).toFixed(2) + "%";
      },
    },
    {
      title: "Precision",
      dataIndex: "precision",
      key: "precision",
      render: (row: number) => {
        return (row * 100).toFixed(2) + "%";
      },
    },
    {
      title: "Recall",
      dataIndex: "recall",
      key: "recall",
      render: (row: number) => {
        return (row * 100).toFixed(2) + "%";
      },
    },
    {
      title: "Selected",
      dataIndex: "selected",
      key: "selected",
      render: (row: boolean, dataIndex: any) => {
        return row ? "true" : "false";
      },
    },
  ];

  const onClickBackBtn = () => {
    navigate(`/model/${modelId}`);
  };

  const showAlert = (type: NoticeType | undefined, message: string): void => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  const numberOfAnnotationsPerDocumentMatchCondition = (): boolean => {
    for (let i = 0; i < amountOfAnnotationPerDocument.length; i++) {
      if (
        amountOfAnnotationPerDocument[i] <
        Config.WebApps.Model.MinNumberAnnotationsForTrain
      ) {
        return false;
      }
    }

    return true;
  };

  const onClickTrainBtn = () => {
    if (
      amountOfDocuments < Config.WebApps.Model.MinNumberDocumentsForTrain &&
      !numberOfAnnotationsPerDocumentMatchCondition()
    ) {
      dispatch(
        showWarningModal(
          t("ModelPage.header.conditionsForTrainAreNotMetWarning", {
            v1: Config.WebApps.Model.MinNumberDocumentsForTrain,
            v2: Config.WebApps.Model.MinNumberAnnotationsForTrain,
          })
        )
      );
    } else {
      modelId && service!.trainModel(modelId);
      dispatch(updateMode(ModelPageMode.ONLINE));
      dispatch(setModelState(ModelState.Train));
    }
  };

  const trainBtnIsClickableCheck = (): void => {
    if (
      amountOfDocuments >= Config.WebApps.Model.MinNumberDocumentsForTrain &&
      numberOfAnnotationsPerDocumentMatchCondition()
    ) {
      setTrainBtnIsClickable(true);
    } else {
      setTrainBtnIsClickable(false);
    }
  };

  const onClickEditBtn = () => {
    loading(true);
    modelId &&
      service!
        .createFromModel(modelId)
        .then(async (newModelId: string) => {
          await loading(false);
          navigate(`/model/${newModelId}`);
          dispatch(updateMode(ModelPageMode.ANNOTATION));
        })
        .catch(async (err: any) => {
          await loading(false);
          showAlert("error", t("ModelPage.alerts.errorWhileCreatingNewModel"));
          console.error(
            "An error occurred on the server while creating new model",
            err
          );
        });
  };

  const onChangeModelName = async (newModelName: string): Promise<string> => {
    return new Promise(
      async (
        resolve: (value: string) => void,
        reject: (reason?: any) => void
      ) => {
        if (modelId) {
          service!
            .updateModelName(modelId, newModelName)
            .then(() => {
              resolve(newModelName);
            })
            .catch((err: any) => {
              showAlert(
                "error",
                t("ModelPage.alerts.errorWhileUpdatingModelName")
              );
              console.error(
                "An error occurred on the server while updating a model name",
                err
              );
              reject();
            });
        } else {
          showAlert("error", t("ModelPage.alerts.invalidModelId"));
          console.error("Invalid model id!");
          reject();
        }
      }
    );
  };

  useEffect(() => {
    trainBtnIsClickableCheck();
  }, [amountOfDocuments, amountOfAnnotationPerDocument]);

  return (
    <div>
      <Row
        className={modelMode !== ModelMode.Test ? s["Header"] : s["TestHeader"]}
        justify="space-between"
        align="middle"
      >
        <Col span={4}>
          <Row align="middle">
            <Col>
              <span
                className={
                  modelMode !== ModelMode.Test
                    ? s["Header-BackBtn"]
                    : s["TestHeader-BackBtn"]
                }
                onClick={() => onClickBackBtn()}
              >
                <CloseOutlined />
              </span>
            </Col>

            <EditModelName
              modelMode={modelMode}
              srcName={modelName}
              onChangeAnnotationName={onChangeModelName}
            />
          </Row>
        </Col>

        <Col span={7} className={s["Header-Title"]}>
          {modelMode !== ModelMode.Test
            ? modelState === ModelState.Create
              ? t("ModelPage.header.modelTitle.create")
              : modelState === ModelState.Train
              ? t("ModelPage.header.modelTitle.train")
              : modelState === ModelState.Online
              ? t("ModelPage.header.modelTitle.online")
              : ""
            : t("ModelPage.header.modelTitle.test")}
        </Col>

        <Col span={9}>
          <Row justify="end">
            {modelState !== ModelState.Train &&
              modelMode !== ModelMode.Test && (
                <Col>
                  {(mode === ModelPageMode.ANNOTATION ||
                    mode === ModelPageMode.EMPTY) &&
                    modelState !== ModelState.Online && (
                      <Button
                        onClick={() => onClickTrainBtn()}
                        className={
                          trainBtnIsClickable
                            ? s["Header-ClickableTrainBtn"]
                            : ""
                        }
                      >
                        {t("ModelPage.header.labelForTrainBtn")}
                      </Button>
                    )}
                  {modelState === ModelState.Online && (
                    <Button
                      className={s["Header-AnalyseBtn"]}
                      onClick={() => setQualityModalVisible(true)}
                    >
                      {t("ModelPage.header.labelForOnlineBtn1")}
                    </Button>
                  )}
                  {modelState === ModelState.Online && (
                    <Button
                      className={s["Header-AnalyseBtn"]}
                      type="primary"
                      onClick={() => {
                        onClickEditBtn();
                      }}
                    >
                      {t("ModelPage.header.labelForOnlineBtn2")}
                    </Button>
                  )}
                  {modelState === ModelState.Online && (
                    <Button
                      className={s["Header-AnalyseBtn"]}
                      type="primary"
                      onClick={() => {
                        navigate(`/model/${modelId}/Test`);
                      }}
                    >
                      {t("ModelPage.header.labelForOnlineBtn3")}
                    </Button>
                  )}
                </Col>
              )}
          </Row>
        </Col>

        {contextHolder}
        <Modal
          className={s["Header-Modal"]}
          title="Quality"
          centered
          footer={false}
          open={qualityModalVisible}
          onCancel={() => {
            setQualityModalVisible(false);
          }}
        >
          <Table
            columns={columns}
            dataSource={quality}
            pagination={false}
            bordered
          />
          <Button
            className={s["Header-Modal-CloseButton"]}
            type="primary"
            onClick={() => {
              setQualityModalVisible(false);
            }}
          >
            Close
          </Button>
        </Modal>
      </Row>
    </div>
  );
}
