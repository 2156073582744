import { AxiosInstance } from "axios";
import IModel from "models/Model";
import IDocument from "models/Document";
import { DocumentStatus } from "models/DocumentStatus";

export default class ModelService {
  axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  async createModel(modelName: string): Promise<string> {
    const formData = new FormData();
    formData.append("name", modelName);
    return this.axiosInstance
      .post(`ModelService/Model/CreateModel/`, formData)
      .then(({ data: { idModel } }) => idModel);
  }

  async createFromModel(idModelOrigine: string): Promise<string> {
    const formData = new FormData();
    formData.append("idModel", idModelOrigine);
    return this.axiosInstance
      .post(`ModelService/Model/CreateFromModel`, formData)
      .then(({ data }) => data)
      .catch((err) => {
        console.log(err);
      });
  }

  async getTrainModelData(idModel: string): Promise<IModel> {
    return this.axiosInstance
      .get(`ModelService/Model/GetModelTrain/${idModel}`)
      .then((response) => {
        response.data.length &&
          response.data.forEach((document: IDocument) => {
            document.status = DocumentStatus.LOADING;
          });

        return response.data;
      });
  }

  async getTestModelData(idModel: string): Promise<IModel> {
    return this.axiosInstance
      .get(`ModelService/Model/GetModelTest/${idModel}`)
      .then((response) => {
        response.data.length &&
          response.data.forEach((document: IDocument) => {
            document.status = DocumentStatus.LOADING;
          });

        return response.data;
      });
  }

  async updateModelName(idModel: string, newModelName: string): Promise<void> {
    return this.axiosInstance.put(`/ModelService/Model/UpdateModel`, {
      idModel,
      name: newModelName,
    });
  }

  async trainModel(idModel: string): Promise<void> {
    return this.axiosInstance.put(`/ModelService/Model/UpdateTrain`, {
      idModel,
    });
  }
}
