import { Spin, message } from "antd";
import { NoticeType } from "antd/es/message/interface";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import IDocument from "../../../../models/Document";
import { useDocumentService } from "../../../../providers/document.service.provider";
import {
  updateMode,
  ModelPageMode,
  updateActiveDocumentId,
  updateDocument,
  removeDocument,
  addEmptyDocument,
} from "../../../../reducers/modelReducer";
import { RootState } from "../../../../reducers/rootReducers";
import s from "./ImportDocument.module.scss";
import { Trans, useTranslation } from "react-i18next";
import { FileUploader } from "react-drag-drop-files";
import {useEffect, useState} from "react";
import { ModelState } from "../../../../models/ModelState";
import { DocumentState } from "../../../../models/DocumentState";
import { DocumentStatus } from "../../../../models/DocumentStatus";
import { DocumentType } from "models/DocumentType";
import { ModelMode } from "models/ModelMode";
import {socket} from "../../../../scripts/initWebsocket";

//  Maximum processing time for each document
const MAX_PROCESSING_TIME_DOCUMENT = 120000 as const

interface IProps {
  modelMode: ModelMode;
}
export default function ImportDocument({ modelMode }: IProps) {
  const { t } = useTranslation();
  const { service } = useDocumentService();
  const dispatch = useDispatch();
  const { modelId } = useParams();
  const [messageApi, contextHolder] = message.useMessage();

  const modelState = useSelector((state: RootState) => state.model.modelState);

  const [loaderIsActive, setLoaderIsActive] = useState<boolean>(false);

  useEffect(() => {
    socket.on('connect', () => {});
    socket.on('disconnect', () => {});
    return () => {
      socket.off('connect', () => {});
      socket.off('disconnect', () => {});
    };
  }, []);


  const calcExpireDate = (): Date => {
    const currentDate: Date = new Date();
    const expireDate = new Date(
      currentDate.setFullYear(currentDate.getFullYear() + 100)
    );

    return expireDate;
  };

  const getDocument = async (documentId: string): Promise<IDocument> => {
    try {
      const document = await service!.getDocument(documentId);
      return document;
    } catch (err) {
      showAlert("error", t("ModelPage.alerts.errorWhileGetingDocument"));
      console.error(
        "An error occurred on the server while geting geting document",
        err
      );
      throw err;
    }
  };

  const createNewDocument = (): string => {
    const newDocumentId = "new-document-" + new Date().getTime();
    const document = {
      idDocument: newDocumentId,
      idModel: "",
      name: "New Document",
      url: "",
      ocrVersion: null,
      pages: [],
      state: DocumentState.New,
      creationDate: "",
      updateDate: "",
      status: DocumentStatus.UPLOADING,
    };

    dispatch(addEmptyDocument(document));

    return newDocumentId;
  };

  const documentSuccessfullyUploadedHandler = (
    document: IDocument,
    uploadingDocumentId: string
  ): void => {
    showAlert(
      "success",
      modelMode === ModelMode.Train
        ? t("ModelPage.alerts.successfullyUploadedPDFFileAlert")
        : t("ModelPage.alerts.successfullyUploadTestPDFFileAlert")
    );

    document.status = DocumentStatus.LOADED;
    dispatch(updateDocument(document, uploadingDocumentId));
    dispatch(updateActiveDocumentId(document.idDocument));
    dispatch(updateMode(ModelPageMode.ANNOTATION));
  };

  const documentSuccessfullyParsedHandler = async (
    idDocument: string
  ): Promise<void> => {
    showAlert("success", t("ModelPage.alerts.successfullyParsedPDFFileAlert"));
    dispatch(updateDocument(await service!.getDocument(idDocument)));
  };

  const socketEmit = (idDocument: string): Promise<string> => {
    return new Promise(function (resolve, reject) {
      socket.emit("message", idDocument , async () => {
        resolve(idDocument);
      });
      setTimeout(reject, MAX_PROCESSING_TIME_DOCUMENT);
    });
  }

  const fileUpload = async (file: File, idModel: string) => {
      if(socket.disconnect) {
        socket.connect()
      }
      const newDocumentId = createNewDocument();
      let document: IDocument;

      try {
        const type = modelMode !== ModelMode.Test ? DocumentType.Training : DocumentType.Testing
        document = await service!.uploadPDFFile(idModel, file, type);
        documentSuccessfullyUploadedHandler(document, newDocumentId);
      } catch (ex) {
        handleErrorWhileUploadingNotPDF();
        dispatch(removeDocument(newDocumentId));
        return
      }

      if (modelMode !== ModelMode.Test && socket.connect) {
        try {
          document.status = DocumentStatus.PARSING;
          dispatch(updateDocument(document));
          /**
           * async Send Document "socket.emit"
           */
          const idDocumentResponse = await socketEmit(document.idDocument)
          if (idDocumentResponse) {
            await documentSuccessfullyParsedHandler(idDocumentResponse);
          }
        } catch (e) {
          handleErrorWhileParsing();
          document.status = DocumentStatus.LOADED;
          dispatch(updateDocument(document));
        }
        socket.disconnect()
      }
  }

  const filesUploaderHandler = async (fileList: FileList): Promise<void> => {
    try {
      const files: File[] = Array.from(fileList);
      if (!(files.length && modelId)) {
        return
      }
      for (const file of files) {
        await fileUpload(file, modelId)
      }
      clearInputAfterUploadingFiles();
    } catch (e) {
      console.error(e)
    }
  };

  const handleErrorWhileUploadingNotPDF = (): void => {
    showAlert("error", t("ModelPage.alerts.errorWhileUploadingNotPDF"));
    console.error(t("ModelPage.alerts.errorWhileUploadingNotPDF"));
  };

  const handleErrorWhileParsing = (): void => {
    showAlert("error", t("ModelPage.alerts.errorWhileParsingNotPDF"));
    console.error(t("ModelPage.alerts.errorWhileParsingNotPDF"));
  };

  const clearInputAfterUploadingFiles = (): void => {
    const $elemInputTypeFile = document.getElementsByName("file")[0];

    $elemInputTypeFile.addEventListener("click", clearInput);
    $elemInputTypeFile.click();
    removeEventListener();

    function clearInput(e: any) {
      e.preventDefault();
      e.target.files = null;
      e.target.value = null;
    }
    function removeEventListener() {
      $elemInputTypeFile.removeEventListener("click", clearInput);
    }
  };

  const showAlert = (type: NoticeType | undefined, message: string): void => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  return (
    <FileUploader
      handleChange={filesUploaderHandler}
      name="file"
      types={["PDF"]}
      multiple
      hoverTitle=" "
      onTypeError={() => handleErrorWhileUploadingNotPDF()}
      disabled={modelState === ModelState.Train ? true : false}
    >
      <Spin tip={t("shared.spinText")} spinning={loaderIsActive}>
        <div className={s["ImportDocument"]}>
          <svg
            className={s["ImportDocument-Icon"]}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="8" fill="#F2F0FF" />
            <path
              d="M16.6667 23.3333L20 20M20 20L23.3334 23.3333M20 20V27.5M26.6667 23.9524C27.6846 23.1117 28.3334 21.8399 28.3334 20.4167C28.3334 17.8854 26.2813 15.8333 23.75 15.8333C23.5679 15.8333 23.3976 15.7383 23.3051 15.5814C22.2184 13.7374 20.212 12.5 17.9167 12.5C14.4649 12.5 11.6667 15.2982 11.6667 18.75C11.6667 20.4718 12.3629 22.0309 13.4891 23.1613"
              stroke="#9482FF"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <div className={s["ImportDocument-Title"]}>
            <Trans
              i18nKey="ModelPage.documentsListSection.importDocumentTitle"
              components={{
                span: <span>Click to upload</span>,
              }}
            ></Trans>
          </div>

          <div className={s["ImportDocument-Subtitle"]}>
            {t("ModelPage.documentsListSection.importDocumentSubtitle")}
          </div>
        </div>
      </Spin>

      {contextHolder}
    </FileUploader>
  );
}
