import { useSelector } from "react-redux";
import { ModelPageMode } from "../../../reducers/modelReducer";
import { RootState } from "../../../reducers/rootReducers";
import ImportDocument from "./ImportDocument/ImportDocument";
import DocumentPreview from "./DocumentPreview/DocumentPreview";
import { ModelState } from "../../../models/ModelState";
import IDocument from "../../../models/Document";
import { ModelMode } from "models/ModelMode";
import { useTranslation } from "react-i18next";

interface IProps {
  modelMode: ModelMode;
}

export default function DocumentsList({ modelMode }: IProps) {
  const { t } = useTranslation();
  const mode = useSelector((state: RootState) => state.model.mode);
  const documents = useSelector((state: RootState) => state.model.documents);
  const modelState = useSelector((state: RootState) => state.model.modelState);
  return (
    <div>
      {modelMode === ModelMode.Test ? (
        <h3>{t("ModelPage.documentsListSection.testModeTitle")}</h3>
      ) : (
        ""
      )}

      {((modelState !== ModelState.Train && modelState !== ModelState.Online) ||
        modelMode === ModelMode.Test) && (
        <ImportDocument modelMode={modelMode} />
      )}

      {documents.map((document: IDocument) => (
        <DocumentPreview
          modelMode={modelMode}
          document={document}
          key={`document-list-item-${document.idDocument}`}
        />
      ))}
    </div>
  );
}
